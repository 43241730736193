import { useEffect, useRef, useState } from "preact/hooks";

export default function SearchButton() {
  const [showSearch, setShowSearch] = useState(false);
  const [query, setQuery] = useState("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    const updatePosition = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        setPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
        });
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
          searchRef.current &&
          !searchRef.current.contains(event.target as Node) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target as Node)
        ) {
          setShowSearch(false);
        }
      };

    updatePosition();
    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);
    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
      document.addEventListener("click", handleClickOutside);
    };
  }, [showSearch]);

  const handleSearch = () => {
    if (query.trim()) {
      window.location.replace(`/search?q=${query}`);
    }
  };

  return (
    <>
      <button ref={buttonRef} class="btn relative" onClick={() => setShowSearch(true)}>
        <i class="fa-solid fa-search icon"></i>
      </button>

      {showSearch && (
        <div 
          ref={searchRef}
          class="position-absolute bg-white p-3 rounded-0 text-center shadow" 
          style={{ 
            transform: 'translate(-50%, 25%)',
            top: `${position.top}px`, 
            left: `${position.left}px`, 
            minWidth: "300px",
            maxWidth: "100%",
            zIndex: 1050 
          }}
          onClick={(e) => e.stopPropagation()}
        >
            
            <div class="flex flex-row gap-3 items-center">
                <input
                  type="text"
                  name="search"
                  autoComplete="search"
                  class="flex form-control rounded-0 py-[9px] flex-wrap-0"
                  style="font-size: 19px"
                  value={query}
                  onChange={(e) => setQuery(e.currentTarget.value)}
                  placeholder="Что вы ищете?"
                />
                <button class="btn-black" onClick={handleSearch}>Найти</button>
                <button onClick={() => setShowSearch(false)}><i class="fa-solid fa-xmark"></i></button>
            </div>
          </div>
      )}
    </>
  );
}